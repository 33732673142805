<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {useDataStore} from '@/store';

const app = window.Telegram.WebApp;
const dataStore = useDataStore();

const fetchDelete = async (type, id) => {
  try {
    app.showConfirm('Точно хотите удалить персонажа?', async (callback) => {
      if (callback) {
        await fetch(`${process.env.VUE_APP_API_LINK}/${type}/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })

        if (type === 'character') {
          dataStore.data.heroes.user_characters = dataStore.data.heroes.user_characters.filter(character => character.id !== id);
        }
        if (type === 'pet') {
          dataStore.data.heroes.user_pets = dataStore.data.heroes.user_pets.filter(pet => pet.id !== id);
        }
      }
    })
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};

const deleteItem = (type, id) => {
  fetchDelete(type, id)
}
</script>
<template>
  <HeaderComponent :title="`Персонажи и питомцы`"/>
  <div v-if="dataStore.data.heroes" class="page-content header-clear-medium">
    <div class="card card-style rounded-s">
      <div class="content">
        <div class="tabs tabs-pill" id="list">
          <div class="tab-controls rounded-m p-1">
            <a class="font-12 rounded-m" data-bs-toggle="collapse" href="#characters"
               aria-expanded="true">Персонажи</a>
            <a class="font-12 rounded-m" data-bs-toggle="collapse" href="#pets"
               aria-expanded="false">Питомцы</a>
          </div>
          <div class="mt-3"></div>
          <div class="collapse show" id="characters" data-bs-parent="#list" style="">
            <div v-if="dataStore.data.heroes.user_characters !== false && dataStore.data.heroes.user_characters && dataStore.data.heroes.user_characters.length > 0"
                 class="list-group list-group-m rounded-m list-group-flush">
              <div
                  v-for="character in dataStore.data.heroes.user_characters"
                  :key="character.id"
                  class="list-group-item ps-0 pe-0">
                <div class="d-flex align-items-center">
                  <div class="col">
                      <p class="font-600 opacity-100 mt-1 mb-0 color-theme">
                        {{ character.gender === 'Мальчик' ? '👦 ' + character.name : '👧 ' + character.name }}
                      </p>
                      <p class="opacity-50 font-12 mt-n1">{{ character.gender }}</p>
                  </div>
                  <div class="col-2 text-end">
                    <i class="bi bi-x-circle-fill color-red-dark font-18"
                       @click="deleteItem('character', character.id)"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center mb-3">
              <div class="ps-1">
                <strong class="font-600">У вас нет персонажей</strong>
              </div>
            </div>
            <div class="divider mb-4"></div>
            <div class="col-12 mb-2 pb-1">
              <router-link :to="`add-character`"
                           class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m">
                Добавить персонажа
              </router-link>
            </div>
          </div>
          <div class="collapse" id="pets" data-bs-parent="#list" style="">
            <div v-if="dataStore.data.heroes.user_pets !== false && dataStore.data.heroes.user_pets && dataStore.data.heroes.user_pets.length > 0"
                 class="list-group list-group-m rounded-m list-group-flush">
              <div
                  v-for="pet in dataStore.data.heroes.user_pets"
                  :key="pet.id"
                  class="list-group-item ps-0 pe-0">
                <div class="d-flex align-items-center">
                  <div class="col">
                    <p class="font-600 opacity-100 mt-1 mb-0 color-theme">{{ pet.name }}</p>
                    <p class="opacity-50 font-12 mt-n1">{{ pet.pet }} / {{ pet.gender }}</p>
                  </div>
                  <div class="col-2 text-end">
                    <i class="bi bi-x-circle-fill color-red-dark font-18"
                       @click="deleteItem('pet', pet.id)"></i>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-center mb-3">
              <div class="ps-1">
                <strong class="font-600">У вас нет питомцев</strong>
              </div>
            </div>
            <div class="divider mb-4"></div>
            <div class="col-12 mb-2 pb-1">
              <router-link :to="`add-pet`"
                           class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m">
                Добавить питомца
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
