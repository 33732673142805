<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import { computed } from 'vue';
import {useDataStore} from '@/store';

const dataStore = useDataStore();
const app = window.Telegram.WebApp;

const data = computed(() => dataStore.data)
const openInvoice = (item) => {
  try {
    app.openInvoice(item, async (status) => {
      if (status === 'paid') {
        await dataStore.fetchData()
        app.showAlert('Ваша оплата принята!')
      }
      if (status === 'failed') {
        app.showAlert('Произошла ошибка во время оплаты')
      }
      if (status === 'pending') {
        app.showAlert(
            'Платёж принят на обработку. Мы в скором времени оповестим вас о статусе оплаты',
        )
      }
    })
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
}
</script>
<template>
  <HeaderComponent :title="`Покупка пакета`"/>
  <div v-if="data?.balance_text && data?.tariffs" class="page-content header-clear-medium">
    <div class="card card-style rounded-s">
      <div class="content">
        <h4 class="font-500 text-center">Баланс: {{data.balance_text}}</h4>
        <div class="divider divider-faded mt-3 mb-4"></div>
        <div class="tabs tabs-pill" id="tariff-list">
          <div class="tab-controls rounded-m p-1">
            <a class="font-12 rounded-m collapsed" data-bs-toggle="collapse" href="#tariff-1"
               aria-expanded="false">№1</a>
            <a class="font-12 rounded-m collapsed" data-bs-toggle="collapse" href="#tariff-2"
               aria-expanded="false">№5</a>
            <a class="font-12 rounded-m" data-bs-toggle="collapse" href="#tariff-3"
               aria-expanded="true">№10</a>
            <a class="font-12 rounded-m collapsed" data-bs-toggle="collapse" href="#tariff-4"
               aria-expanded="false">№50</a>
          </div>
          <div class="mt-3"></div>
          <div class="collapse" id="tariff-1" data-bs-parent="#tariff-list" style="">
            <p class="color-highlight mb-n1 font-600">Магическое Мгновение</p>
            <h2 class="mb-2">Цена: {{$formatPrice(data.tariffs.tariff_1.price)}}</h2>
            <p class="mb-0">Включает: 1 рассказ/историю</p>
            <p class="mb-3">Идеально для: Одной особенной истории</p>
            <a href="#" @click="openInvoice(data.tariffs.tariff_1.invoice)"
               class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m invoices">💳
              Купить</a>
          </div>
          <div class="collapse" id="tariff-2" data-bs-parent="#tariff-list" style="">
            <p class="color-highlight mb-n1 font-600">Сказочный Набор</p>
            <h2 class="mb-0">Цена: {{$formatPrice(data.tariffs.tariff_5.price)}}</h2>
            <p class="opacity-80 font-14 font-600 mb-2">экономия - {{$formatPrice(data.tariffs.tariff_5.economy)}}</p>
            <p class="mb-0">Включает: 5 рассказов/историй</p>
            <p class="mb-0">Стоимость за рассказ/историю: <span class="font-600">{{$formatPrice(data.tariffs.tariff_5.for_one)}}</span></p>
            <p class="mb-3">Идеально для: Серии увлекательных историй</p>
            <a href="#" @click="openInvoice(data.tariffs.tariff_5.invoice)"
               class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m invoices">💳
              Купить</a>
          </div>
          <div class="collapse show" id="tariff-3" data-bs-parent="#tariff-list" style="">
            <p class="color-highlight mb-n1 font-600">Вдохновляющая Коллекция</p>
            <h2 class="mb-0">Цена: {{$formatPrice(data.tariffs.tariff_10.price)}}</h2>
            <p class="opacity-80 font-14 font-600 mb-2">экономия - {{$formatPrice(data.tariffs.tariff_10.economy)}}</p>
            <p class="mb-0">Включает: 10 рассказов/историй</p>
            <p class="mb-0">Стоимость за рассказ/историю: <span class="font-600">{{$formatPrice(data.tariffs.tariff_10.for_one)}}</span></p>
            <p class="mb-3">Идеально для: Расширения горизонтов творчества</p>
            <a href="#" @click="openInvoice(data.tariffs.tariff_10.invoice)"
               class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m invoices">💳
              Купить</a>
          </div>
          <div class="collapse" id="tariff-4" data-bs-parent="#tariff-list" style="">
            <p class="color-highlight mb-n1 font-600">Бесконечное Волшебство</p>
            <h2 class="mb-0">Цена: {{$formatPrice(data.tariffs.tariff_50.price)}}</h2>
            <p class="opacity-80 font-14 font-600 mb-2">экономия - {{$formatPrice(data.tariffs.tariff_50.economy)}}</p>
            <p class="mb-0">Включает: 50 рассказов/историй</p>
            <p class="mb-0">Стоимость за рассказ/историю: <span class="font-600">{{$formatPrice(data.tariffs.tariff_50.for_one)}}</span></p>
            <p class="mb-3">Идеально для: Непрерывного творческого полёта</p>
            <a href="#" @click="openInvoice(data.tariffs.tariff_50.invoice)"
               class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m invoices">💳 Купить</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>