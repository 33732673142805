<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {ref, onMounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';

const router = useRouter();
const fileLink = ref(false);

fileLink.value = process.env.VUE_APP_FILE_LINK

const app = window.Telegram.WebApp
const BackButton = app.BackButton

BackButton.show()
BackButton.onClick(function () {
  BackButton.hide()
  router.go(-1)
})

const story = ref(false);
const route = useRoute();

const fetchResponse = async () => {
  try {
    const storyHash = route.params.hash;
    const response = await fetch(`${process.env.VUE_APP_API_LINK}/free-story/${storyHash}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    story.value = data.story
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};

onMounted(fetchResponse);
</script>
<template>
  <HeaderComponent/>
  <div v-if="story" class="page-content header-clear-medium">
    <div class="card card-style shadow-card shadow-card-l rounded-s"
         :style="{ backgroundImage: 'url(' + story.preview + ')', height: '300px' }">
    </div>
    <div v-if="story.audio" class="card card-style shadow-l">
      <audio :src="`${fileLink}${story.audio}`" :controls=true class="w-100"></audio>
    </div>
    <div class="card card-style rounded-s">
      <div class="content">
        <h4 class="text-center font-22">{{ story.title }}</h4>
        <div class="divider divider-faded mt-3 mb-4"></div>
        <p v-for="(paragraph, index) in story.text" :key="index" class="font-18">
          {{ paragraph }}
        </p>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
