<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {ref, onMounted} from 'vue';

const voices = ref([
  {
    name: 'Nova',
    link: `${process.env.VUE_APP_FILE_LINK}/files/nova.mp3`,
  },
  {
    name: 'Лера',
    link: `${process.env.VUE_APP_FILE_LINK}/files/lera.mp3`,
  },
  {
    name: 'Лера (спокойный голос)',
    link: `${process.env.VUE_APP_FILE_LINK}/files/lera_neutral.mp3`,
  },
  {
    name: 'Марина',
    link: `${process.env.VUE_APP_FILE_LINK}/files/marina.mp3`,
  },
  {
    name: 'Маша',
    link: `${process.env.VUE_APP_FILE_LINK}/files/masha.mp3`,
  },
  {
    name: 'Даша',
    link: `${process.env.VUE_APP_FILE_LINK}/files/dasha.mp3`,
  },
]);

const audioElements = ref([]);

onMounted(() => {
  audioElements.value = [...document.querySelectorAll('.voices')];
});

const handlePlay = (event) => {
  audioElements.value.forEach(audio => {
    if (audio !== event.target) {
      audio.pause();
      audio.currentTime = 0; // Сбрасываем время, чтобы начать сначала при следующем воспроизведении
    }
  });
};
</script>
<template>
  <HeaderComponent :title="`Прослушивание голосов`"/>

  <div class="page-content header-clear-medium">
    <div class="card card-style card-overflow-visible rounded-s">
      <div class="content">
        <div class="row">
          <div
              v-for="voice in voices"
              :key="voice.name"
              class="col-12">
            <h4 class="mb-3">
              <strong class="font-600">{{ voice.name }}</strong>
            </h4>
            <audio :src=voice.link :controls=true @play="handlePlay" class="w-100 voices"></audio>
            <div class="divider mb-3 mt-3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
