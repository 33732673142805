<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {useDataStore} from '@/store';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import axios from "axios";
const router = useRouter();

const dataStore = useDataStore();

const app = window.Telegram.WebApp
const BackButton = app.BackButton

BackButton.show()
BackButton.onClick(function () {
  BackButton.hide()
  router.go(-1)
})

const submitClicked = ref(false);
const handleSubmit = async (item) => {
  submitClicked.value = true
  const form = item.target;
  if (!form.checkValidity()) {
    const invalidInput = Array.from(form.elements).find(input => !input.validity.valid);
    if (invalidInput) {
      invalidInput.scrollIntoView({behavior: 'smooth'});
      submitClicked.value = false
    }
  } else {
    const data = {};
    for (let i = 0, ii = form.length; i < ii; ++i) {
      const input = form[i];
      if (input.name) {
        data[input.name] = input.value;
      }
    }
    try {
      const response = await axios.post(
          `${process.env.VUE_APP_API_LINK}/pets`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
      );
      await dataStore.fetchData()
      let text = 'Новый персонаж успешно создан!'
      if (!response.data.ok) {
        text = 'Ошибка создания персонажа, попробуйте попозже!'
      }
      form.reset();
      router.go(-1)
      app.showAlert(text)
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      submitClicked.value = false
    }
  }
  form.classList.add('was-validated');
}
</script>
<template>
  <HeaderComponent :title="`Добавление питомца`"/>
  <div class="page-content header-clear-medium">
    <div class="card card-style card-overflow-visible rounded-s">
      <div class="content">
        <form id="addPetForm" @submit.prevent="handleSubmit" class="validator addForm" novalidate>
          <input type="hidden" name="add_type" value="pet"/>
          <fieldset>
            <div class="row">
              <div class="col-12">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <input type="text" name="pet" class="pet form-control rounded-xs" placeholder="Питомец, например Собачка или Кошка" required />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <input type="text" name="name" class="name form-control rounded-xs" placeholder="Имя" required />
                </div>
              </div>
              <div class="col-5">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <select  name="age" class="form-select rounded-xs gender"
                           aria-label="Возраст"
                           required>
                    <option value="" disabled selected hidden>Возраст</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <select  name="gender" class="form-select rounded-xs gender"
                           aria-label="Выберите пол"
                           required>
                    <option value="Мальчик">Мальчик</option>
                    <option value="Девочка">Девочка</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <input type="text" name="color" class="hair_color form-control rounded-xs" placeholder="Окрас" required />
                </div>
              </div>
              <div class="col-12">
                <div class="form-custom form-label form-icon mb-3">
                  <i class="bi bi-check-circle font-13"></i>
                  <input type="text" name="eye_color" class="eye_color form-control rounded-xs" placeholder="Цвет глаз" required />
                </div>
              </div>
              <div class="col-12">
                <div class="form-custom form-label mb-3">
                  <textarea name="description" class="form-control rounded-xs" placeholder="Описание"></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-button">
                  <button :disabled="submitClicked" class="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4" type="submit">Добавить</button>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>