import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import FreeStoryPage from "@/pages/FreeStoryPage.vue";
import MyStoriesPage from "@/pages/MyStoriesPage.vue";
import StoryPage from "@/pages/StoryPage.vue";
import SharedStoryPage from "@/pages/SharedStoryPage.vue";
import BuyPage from "@/pages/BuyPage.vue";
import CreatePage from "@/pages/CreatePage.vue";
import HeroesPage from "@/pages/HeroesPage.vue";
import AddCharacterPage from "@/pages/AddCharacterPage.vue";
import AddPetPage from "@/pages/AddPetPage.vue";
import VoicesPage from "@/pages/VoicesPage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/voice",
    name: "VoicesPage",
    component: VoicesPage,
  },
  {
    path: "/buy",
    name: "BuyPage",
    component: BuyPage,
  },
  {
    path: "/free-story/:hash",
    name: "FreeStoryPage",
    component: FreeStoryPage,
  },
  {
    path: "/my-stories",
    name: "MyStoriesPage",
    component: MyStoriesPage,
  },
  {
    path: "/story/:hash",
    name: "StoryPage",
    component: StoryPage,
  },
  {
    path: "/shared-story/:hash",
    name: "SharedStoryPage",
    component: SharedStoryPage,
  },
  {
    path: "/create",
    name: "CreatePage",
    component: CreatePage,
  },
  {
    path: "/heroes",
    name: "HeroesPage",
    component: HeroesPage,
  },
  {
    path: "/add-character",
    name: "AddCharacterPage",
    component: AddCharacterPage,
  },
  {
    path: "/add-pet",
    name: "AddPetsPage",
    component: AddPetPage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active-nav",
});

export default router;
