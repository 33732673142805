<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {useDataStore} from '@/store';

const dataStore = useDataStore();
const app = window.Telegram.WebApp

const fetchDelete = async (id) => {
  try {
    app.showConfirm('Точно хотите удалить историю?', async (callback) => {
      if (callback) {
        await fetch(`${process.env.VUE_APP_API_LINK}/story/${id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        })
        const currentStories = dataStore.data.user_stories
        dataStore.data.user_stories = currentStories.filter(item => item.id !== id);
      }
    })
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};

const deleteItem = (id) => {
  fetchDelete(id)
}
</script>
<template>
  <HeaderComponent :title="`🪄 Ваши истории`"/>
  <div v-if="dataStore.data.user_stories" class="page-content header-clear-medium">
    <div v-if="dataStore.data.user_stories !== false && dataStore.data.user_stories && dataStore.data.user_stories.length > 0">
      <div class="card card-style rounded-s">
        <div class="content">
          <div class="list-group list-group-m rounded-m list-group-flush">
            <div v-for="story in dataStore.data.user_stories"
                 :key="story.id"
                 class="list-group-item ps-0 pe-0">
              <div class="d-flex align-items-center">
                <div class="col-3">
                  <router-link :to="`story/${story.link}`">
                    <img v-lazy="`${story.preview}`" width="70" height="70" class="me-2 rounded-xs lazy-img-fadein" alt="" src="">
                  </router-link>
                </div>
                <div class="col">
                  <router-link :to="`story/${story.link}`" class="color-theme font-16 font-500">
                    {{ story.title }}
                  </router-link>
                </div>
                <div class="col-2 text-end">
                  <i class="bi bi-x-circle-fill color-red-dark font-18" @click="deleteItem(story.id)"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card card-style rounded-s">
      <div class="content text-center">
        <h3 class="mb-3">У вас нет созданных историй</h3>
        <router-link :to="'create'"
                     class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m">
          Создать
        </router-link>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
