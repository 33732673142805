<script setup>
import {onMounted, ref} from 'vue';

const voices = ref([
  {
    name: 'Nova',
    link: `${process.env.VUE_APP_FILE_LINK}/files/nova.mp3`,
  },
  {
    name: 'Лера',
    link: `${process.env.VUE_APP_FILE_LINK}/files/lera.mp3`,
  },
  {
    name: 'Лера (спокойный голос)',
    link: `${process.env.VUE_APP_FILE_LINK}/files/lera_neutral.mp3`,
  },
  {
    name: 'Марина',
    link: `${process.env.VUE_APP_FILE_LINK}/files/marina.mp3`,
  },
  {
    name: 'Маша',
    link: `${process.env.VUE_APP_FILE_LINK}/files/masha.mp3`,
  },
  {
    name: 'Даша',
    link: `${process.env.VUE_APP_FILE_LINK}/files/dasha.mp3`,
  },
]);
const audioElements = ref([]);

onMounted(() => {
  audioElements.value = [...document.querySelectorAll('.voices')];
});

const handlePlay = (event) => {
  audioElements.value.forEach(audio => {
    if (audio !== event.target) {
      audio.pause();
      audio.currentTime = 0; // Сбрасываем время, чтобы начать сначала при следующем воспроизведении
    }
  });
};
</script>
<template>
  <div id="voices-list" :style="`height: auto`" class="offcanvas offcanvas-bottom offcanvas-detached rounded-s h-90">
    <div class="content">
      <div class="d-flex">
        <div>
          <h1 class="mb-0 font-700 font-20">Прослушивание голосов</h1>
        </div>
        <div class="ms-auto me-n2">
          <a href="#" data-bs-dismiss="offcanvas" class="icon icon-xs mt-n1"><i
              class="bi bi-x-circle-fill color-red-dark font-18"></i></a>
        </div>
      </div>
      <div class="divider mb-4 mt-2"></div>
      <div class="row">
        <div
            v-for="voice in voices"
            :key="voice.name"
            class="col-12">
          <h4 class="mb-3">
            <strong class="font-600">{{ voice.name }}</strong>
          </h4>
          <audio :src="`${voice.link}`" :controls=true @play="handlePlay" class="w-100 voices"></audio>
          <div class="divider mb-3 mt-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>