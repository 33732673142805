import { defineStore } from "pinia";
import axios from "axios";
export const useDataStore = defineStore("dataStore", {
  state: () => ({
    data: [],
    auth_data: [],
  }),
  actions: {
    async auth(initData) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_LINK}/auth`,
          {
            _auth: initData,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
        );
        this.auth_data = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_LINK}/account_data`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
        );
        this.data = response.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
