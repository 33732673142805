<script setup>
import {computed, ref} from 'vue';
import HeaderComponent from '../components/HeaderComponent.vue';
import VoicesComponent from '../components/VoicesComponent.vue';
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '/public/styles/splide.css';
import {useDataStore} from '@/store';
import axios from "axios";

const app = window.Telegram.WebApp;
const dataStore = useDataStore();

const data = computed(() => dataStore.data);

const selectedGenre = ref('Приключение')

const selectGenre = (genre) => {
  selectedGenre.value = genre;
};
const splideOptions = {
  type: 'slide',
  perPage: 2,
  perMove: 1,
  autoplay: false,
  pagination: false,
  arrows: false,
  fixedWidth: 150,
};

const handleSubmit = async (item) => {
  const form = item.target;
  if (!form.checkValidity()) {
    const invalidInput = Array.from(form.elements).find(input => !input.validity.valid);
    if (invalidInput) {
      invalidInput.scrollIntoView();
    }
  } else {
    const data = {};
    for (let i = 0, ii = form.length; i < ii; ++i) {
      const input = form[i];
      if (input.name) {
        data[input.name] = input.value;
      }
    }
    try {
      const response = await axios.post(
          `${process.env.VUE_APP_API_LINK}/save`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials: true,
          },
      );
      form.reset();
      if (response.data.ok) {
        const text = 'Пожалуйста, подождите немного, пока наш волшебный рассказчик создаёт сказку для вашего ребенка. Этот волшебный процесс может занять несколько минут. Спасибо за ваше терпение! Мы уведомим вас, когда история будет готова.'
        app.showAlert(text)
        app.close()
      } else {
        data.balance = response.data.balance
        app.showAlert(response.data.message)
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
    }
  }
  form.classList.add('was-validated');
}
</script>
<template>
  <VoicesComponent/>
  <HeaderComponent :title="`🪄 Время творить`"/>

  <div v-if="data.create_params" class="page-content header-clear-medium">
    <div v-if="data.balance > 0">
      <div
          v-if="data.heroes.user_characters !== false && data.heroes.user_characters && data.heroes.user_characters.length > 0"
          class="card card-style card-overflow-visible rounded-s">
        <div class="content">
          <p class="mb-4">
            Создайте мир, в котором ваш ребенок будет звездой.
          </p>
          <form id="saveForm" @submit.prevent="handleSubmit" class="m-0 validator addForm" novalidate>
            <div class="divider divider-faded mt-4 mb-4"></div>
            <h5 class="font-700 opacity-80 mb-2">Главный герой</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-main-character"
                      aria-label="Выберите главного героя"
                      required>
                <option value='' disabled selected hidden>Не выбрано</option>
                <option v-for="character in data.heroes.user_characters" :key="character.id"
                        :value="character.id">
                  {{ character.name }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mb-2">Выберите животное</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-pet" aria-label="Выберите тему"
                      required>
                <option :value=0 selected>Не выбрано</option>
                <option v-for="pet in data.heroes.user_pets" :key="pet.id" :value="pet.id">
                  {{ pet.pet }} - {{ pet.name }}
                </option>
              </select>
            </div>
            <div class="divider divider-faded mt-4 mb-4"></div>
            <h5 class="font-700 opacity-80 mt-3 mb-2">Выберите голос озвучки
              <a href="#" data-bs-toggle="offcanvas" data-bs-target="#voices-list" class="opacity-70 font-10 ms-1">(прослушать)</a>
            </h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-voice" aria-label="Выберите голос озвучки" required>
                <option value='' selected>Не выбрано</option>
                <option v-for="voice in data.create_params.voices" :key="voice.key" :value="voice.key">
                  {{ voice.name }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mb-2">
              Выберите жанр
              <span class="opacity-70 font-10">(можно листать)</span>
            </h5>
            <Splide :options=splideOptions class="slider-no-dots slider-no-arrows">
              <SplideSlide v-for="(genre) in data.create_params.params.genre" :key="genre.id"
                           class="text-center me-3">
                <div
                    :class="{'opacity-100 shadow-card-l': selectedGenre === genre.title, 'opacity-40': selectedGenre !== genre.title}"
                    class="card rounded-s genre"
                    :style="`height: 100px; background-image: url(${genre.image})`"
                    @click="selectGenre(genre.title)">
                </div>
                <p>{{ genre.title }}</p>
              </SplideSlide>
            </Splide>
            <input type="hidden" name="story-genre" :value="selectedGenre">
            <h5 class="font-700 opacity-80 mt-3 mb-2">Выберите тему</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-theme" aria-label="Выберите тему" required>
                <option v-for="theme in data.create_params.params.theme" :key="theme.title"
                        :value="theme.title">
                  {{ theme.title }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mt-3 mb-2">Варианты мира и действия</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-setting"
                      aria-label="Выберите вариант мира и действия"
                      required>
                <option v-for="setting in data.create_params.params.settings" :key="setting.title"
                        :value="setting.title">
                  {{ setting.title }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mt-3 mb-2">Варианты стиль повествования</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-style"
                      aria-label="Выберите стиль повествования"
                      required>
                <option v-for="style in data.create_params.params.style" :key="style.title"
                        :value="style.title">
                  {{ style.title }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mt-3 mb-2">Желаемый урок или мораль</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-check-circle font-13"></i>
              <select class="form-select rounded-xs" name="story-moral"
                      aria-label="Выберите мораль / урок"
                      required>
                <option v-for="moral in data.create_params.params.moral" :key="moral.title"
                        :value="moral.title">
                  {{ moral.title }}
                </option>
              </select>
            </div>
            <h5 class="font-700 opacity-80 mt-3 mb-2">Пожелания или магические предметы</h5>
            <div class="form-custom form-label form-icon mb-3">
              <i class="bi bi-pencil-fill font-12"></i>
              <textarea class="form-control rounded-xs" placeholder="Введите текст..."
                        name="story-special-items"></textarea>
            </div>
            <button
                class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m mt-4"
                type="submit">Создать
            </button>
          </form>
        </div>
      </div>
      <div v-else class="card card-style">
        <div class="content text-center">
          <h3 class="mb-3">У вас нет персонажей</h3>
          <router-link :to="`heroes`"
                       class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m">
            Добавить
          </router-link>
        </div>
      </div>
    </div>
    <div v-else class="card card-style">
      <div class="content text-center">
        <h4 class="mb-3 font-200">Баланс: {{ data.balance_text }}</h4>
        <h4 class="mb-3 font-200">Кажется, у вас закончились попытки создания историй. 🌟</h4>
        <h4 class="mb-3 font-200">Но не волнуйтесь! Просто нажмите на кнопку ниже, чтобы добавить ещё
          волшебства в ваш аккаунт и продолжить создание удивительных сказок. ✨</h4>
        <router-link :to="'buy'"
                     class="btn btn-full gradient-highlight rounded-xs text-uppercase font-700 w-100 btn-s shadow-bg shadow-bg-m">
          💳 Купить пакет
        </router-link>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
