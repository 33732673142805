<script setup>
import {ref, onMounted} from 'vue';
import {useRoute} from 'vue-router';

const story = ref(false);
const storyHash = ref(false);
const link = ref(false);
const fileLink = ref(false);

fileLink.value = process.env.VUE_APP_FILE_LINK

const route = useRoute();
storyHash.value = route.params.hash;
const fetchResponse = async () => {
  try {
    const response = await fetch(`${process.env.VUE_APP_API_LINK}/share-story/${storyHash.value}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    story.value = data.story
    link.value = data.link
  } catch (error) {
    console.error('Ошибка при получении данных:', error);
  }
};
onMounted(fetchResponse);
</script>
<template>
  <div v-if="story" class="page-content pt-3">
    <div class="card card-style shadow-card shadow-card-l rounded-s"
         :style="{ backgroundImage: 'url(' + story.preview + ')', height: '300px' }">
    </div>
    <div v-if="story.audio" class="card card-style shadow-l">
      <audio :src="`${fileLink}${story.audio}`" :controls=true class="w-100"></audio>
    </div>
    <div class="card card-style rounded-s">
      <div class="content">
        <h4 class="text-center font-22">{{ story.title }}</h4>
        <div class="divider divider-faded mt-3 mb-4"></div>
        <p v-for="(paragraph, index) in story.text" :key="index" class="font-18">
          {{ paragraph }}
        </p>
        <div class="divider"></div>
        <div class="col-12 mb-3">
          <a :href="`https://t.me/MagicStoriesBot?start=hash_${storyHash}`" target="_blank"
             class="btn-full btn bg-fade2-blue color-blue-dark"><span class="me-2">🤩</span>Я хочу свою историю</a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>
