<script setup>
import HeaderComponent from '../components/HeaderComponent.vue';
import {useDataStore} from '@/store';
const dataStore = useDataStore();
</script>
<template>
  <HeaderComponent/>
  <div v-if="dataStore.data.free_stories" class="page-content header-clear-medium">
    <div class="card card-style shadow-card shadow-card-l rounded-s"
         :style="{ height: '200px', backgroundImage: `url(https://telegra.ph/file/c8bd917dd5d5dd3163484.jpg)` }">
      <div class="card-bottom pb-3 px-3">
        <h4 class="color-white">Добро пожаловать в Magic Stories</h4>
        <p class="color-white opacity-70 mb-0 mt-n1">Там, где начинается волшебство</p>
      </div>
      <div class="card-overlay bg-gradient-fade opacity-80"></div>
    </div>
    <div class="divider divider-icon"><i class="bi bi-heart-fill color-red-dark font-23"></i><em></em></div>

    <div class="card card-style rounded-s">
      <h5 class="font-700 content text-center"><span class="me-2">🪄</span>Волшебные истории для Вас</h5>
    </div>
    <div class="row mb-0">
      <div v-for="(stories, index) in dataStore.data.free_stories" :key="stories" class="col-6"
           :class="{'pe-0': index % 2 === 0, 'ps-0': index % 2 !== 0}">
        <router-link :to="'/free-story/' + stories.link">
          <div
              class="card card-style shadow-card shadow-l rounded-s"
              :style="{ height: '200px', backgroundImage: 'url(' + stories.content.preview + ')' }">
            <div v-if="stories.content.audio" class="card-top text-end m-2">
              <i class="bi bi-volume-up color-white font-30"></i>
            </div>
            <div class="card-bottom pb-3 px-3">
              <h5 class="color-white">{{ stories.content.title }}</h5>
            </div>
            <div class="card-overlay-2 bg-gradient-fade opacity-80"></div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>