<script setup>
import {computed, ref} from 'vue';

import {useDataStore} from "@/store";
const dataStore = useDataStore();

const data = computed(() => dataStore.data);

// eslint-disable-next-line no-undef
defineProps({
  title: {
    type: String,
    default: 'MagicStories',
    required: false
  }
});
const theme = ref('theme-light'); // По умолчанию светлая тема
const applyTheme = () => {
  if (theme.value === 'dark-mode') {
    document.body.classList.add('theme-dark');
    document.body.classList.remove('theme-light');
    localStorage.theme = 'dark-mode'
  } else {
    document.body.classList.add('theme-light');
    document.body.classList.remove('theme-dark');
    localStorage.theme = 'light-mode'
  }
};

const toggleTheme = () => {
  const app = window.Telegram.WebApp
  if (document.body.className === 'theme-light') {
    app.setHeaderColor('#18191a')
    app.setBackgroundColor('#18191a')
    theme.value = 'dark-mode';
  } else if (document.body.className === 'theme-dark') {
    app.setHeaderColor('#f1f1f7')
    app.setBackgroundColor('#f1f1f7')
    theme.value = 'light-mode';
  }
  applyTheme();
};

</script>
<template>
  <div class="header-app header-bar header-fixed header-center header-bar-detached shadow-l rounded-s">
    <a class="show-on-theme-light" @click="toggleTheme"><i class="bi bi-moon-fill font-13"></i></a>
    <a class="show-on-theme-dark" @click="toggleTheme"><i
        class="bi bi-lightbulb-fill color-yellow-dark font-13"></i></a>
    <a href="#" class="header-title">{{ title }}</a>
    <router-link to="/buy"><em class="badge bage-number bg-red-dark">{{ data.balance }}</em><i
        class="font-18 bi bi-cart3"></i></router-link>
  </div>
  <div id="footer-bar" class="footer-bar footer-m-style">
    <router-link to="/voice"><i class="bi bi-soundwave font-18"></i><span>Голоса</span></router-link>
    <router-link to="/my-stories"><i class="bi bi-card-text font-18"></i><span>Истории</span></router-link>
    <router-link to="/"><i class="bi bi-house-fill font-18"></i><span>Главная</span></router-link>
    <router-link to="/create"><i class="bi bi-stars font-18"></i><span>Создать</span></router-link>
    <router-link to="/heroes"><i class="bi bi-person font-18"></i><span>Перс.</span></router-link>
  </div>
</template>