<template>
  <router-view v-if="isAuthCompleted"></router-view>
  <div v-else class="d-flex align-items-center justify-content-center"
       :style="{ height: '100vh' }">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useDataStore} from '@/store';

const dataStore = useDataStore();
const app = window.Telegram.WebApp

const isAuthCompleted = ref(false)

app.expand()
app.isClosingConfirmationEnabled = true

onMounted(async () => {
  const savedTheme = localStorage.theme;
  const app = window.Telegram.WebApp
  if (savedTheme === 'dark-mode') {
    if (app.isVersionAtLeast('6.9')) {
      app.setHeaderColor('#18191a')
      app.setBackgroundColor('#18191a')
    }
    document.body.classList.add('theme-dark');
    document.body.classList.remove('theme-light');
  } else {
    if (app.isVersionAtLeast('6.9')) {
      app.setHeaderColor('#f1f1f7')
      app.setBackgroundColor('#f1f1f7')
    }
    document.body.classList.add('theme-light');
    document.body.classList.remove('theme-dark');
  }

  if (app.platform !== 'unknown') {
    await dataStore.auth(app.initData)
    if (dataStore.auth_data?.balance_text) {
      isAuthCompleted.value = true
      await dataStore.fetchData()
    }
  } else {
    isAuthCompleted.value = true
  }
});
</script>